import { FetchParams } from './types.fetch';
import { AppPostSemantics, ParsePostResult, RefMeta } from './types.parser';
import { PlatformPost } from './types.platform.posts';
import { PLATFORM } from './types.platforms';
import { RefDisplayMeta } from './types.references';
import { AppUserRead } from './types.user';

export interface GenericAuthor {
  platformId: PLATFORM;
  id: string;
  username: string;
  name: string;
  avatarUrl?: string;
}

export interface GenericPost {
  url?: string;
  content: string;
  quotedThread?: GenericThread;
}

export interface GenericThread {
  url?: string;
  thread: GenericPost[];
  author: GenericAuthor;
}

/** Structured semantics */
export interface StructuredSemantics {
  labels?: string[];
  keywords?: string[];
  refs?: string[];
  refsMeta?: Record<string, RefMeta>;
  topic?: string;
}

export type ArrayIncludeQuery = string[];

export interface StructuredSemanticsQuery {
  labels?: ArrayIncludeQuery;
  keywords?: ArrayIncludeQuery;
  refs?: ArrayIncludeQuery;
  topic?: string;
}

/**
 * AppPost object as stored on our database
 *  */
export enum AppPostParsingStatus {
  IDLE = 'idle',
  PROCESSING = 'processing',
  ERRORED = 'errored',
  EXPIRED = 'expired',
}
export enum AppPostParsedStatus {
  UNPROCESSED = 'unprocessed',
  PROCESSED = 'processed',
}
export enum AppPostEditStatus {
  PENDING = 'pending',
  DRAFT = 'draft',
  UPDATED = 'updated',
}

interface AppPostBase {
  id: string; // the id may be autogenerated by the DB or computed from an original platform post_id
  generic: GenericThread;
  authorUserId?: string; // Defined only if author is a signedup user
  authorProfileId: string;
  origin: PLATFORM; // The platform where the post originated
  createdAtMs: number;
  parsingStatus: AppPostParsingStatus;
  parsingStartedAtMs?: number;
  parsedStatus: AppPostParsedStatus;
  editStatus: AppPostEditStatus;
  originalParsed?: ParsePostResult;
  semantics?: AppPostSemantics;
  mirrorsIds: string[];
}

export interface AppPost extends AppPostBase {
  structuredSemantics?: StructuredSemantics; // for indexing purposes. Will be duplicated across subcollections
}

export interface HydrateConfig {
  addMirrors?: boolean;
  addAggregatedLabels?: boolean;
}

export interface PostReadMeta {
  references: Record<string, RefDisplayMeta>;
}
export interface AppPostRead extends AppPost {
  meta?: PostReadMeta;
}

export type AppPostCreate = Omit<AppPost, 'id'>;

/**
 * Wrapper object that joins an AppPost, all its mirrors and its
 * author profile (including credentials). Useful to transfer publishing
 * information between services
 * */
export interface AppPostFull extends Omit<AppPostRead, 'mirrorsIds'> {
  mirrors?: PlatformPost[];
}

export interface PostAndAuthor {
  post: AppPostFull;
  author: AppUserRead;
}

/**
 * Payload to mirror a post on other platforms,
 */
export interface AppPostMirror {
  postId: string;
  content?: string;
  semantics?: AppPostSemantics;
  mirrors: PlatformPost[];
}

/**
 * PostUpdate
 */
export type PostUpdate = Partial<
  Pick<
    AppPost,
    | 'authorUserId'
    | 'generic'
    | 'semantics'
    | 'structuredSemantics'
    | 'originalParsed'
    | 'parsingStatus'
    | 'parsingStartedAtMs'
    | 'parsedStatus'
    | 'editStatus'
  >
>;

export interface PostUpdatePayload {
  postId: string;
  postUpdate: PostUpdate;
}

export interface UnpublishPlatformPostPayload {
  postId: string;
  platformId: PLATFORM;
  post_id: string;
}

export interface PostsQueryParams {
  userId?: string;
  profileId?: string;
  origins?: ArrayIncludeQuery;
  semantics?: StructuredSemanticsQuery;
  hydrateConfig?: HydrateConfig;
}

export interface PostsQuery extends PostsQueryParams {
  fetchParams?: FetchParams;
}

export type PostsQueryDefined = Omit<PostsQuery, 'fetchParams'> & {
  fetchParams: FetchParams;
};

export interface ProfilePostsQuery {
  platformId: PLATFORM;
  username: string;
  labelsUris?: string[];
  fetchParams: FetchParams;
}
export interface MastodonGetContextParams {
  mastodonServer: string;
  callback_url: string;
  type: 'read' | 'write';
}

export interface GetPostPayload {
  postId: string;
  config?: HydrateConfig;
}
