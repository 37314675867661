export const THIS_POST_NAME_URI = 'https://sense-nets.xyz/mySemanticPost';
export const THIS_POST_NAME_URI_PLACEHOLDER =
  'http://purl.org/nanopub/temp/mynanopub#assertion';
export const HAS_KEYWORD_URI = 'https://schema.org/keywords';
export const HAS_TOPIC_URI = 'https://schema.org/topic';
export const SCIENCE_TOPIC_URI = 'https://sense-nets.xyz/isScienceRelated';
export const NOT_SCIENCE_TOPIC_URI =
  'https://sense-nets.xyz/isNotScienceRelated';
export const HAS_ZOTERO_REFERENCE_TYPE_URI =
  'https://sense-nets.xyz/hasZoteroItemType';
export const HAS_RDF_SYNTAX_TYPE_URI =
  'http://www.w3.org/1999/02/22-rdf-syntax-ns#type';
