import { Box } from 'grommet';

export const AppIcon = (props: { size?: number }) => {
  const size = props.size || 18;
  return (
    <Box pad={`${size * 0.2}px`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 18 18"
        fill="none">
        <path
          d="M9.93304 6.19993H8.06638C7.03971 6.19993 6.19971 7.03993 6.19971 8.06659V9.93326C6.19971 10.9599 7.03971 11.7999 8.06638 11.7999H9.93304C10.9597 11.7999 11.7997 10.9599 11.7997 9.93326V8.06659C11.7997 7.03993 10.9597 6.19993 9.93304 6.19993Z"
          fill="#17494D"
        />
        <path
          d="M15.5336 11.8H13.667C12.6403 11.8 11.8003 12.64 11.8003 13.6667V15.5334C11.8003 16.56 12.6403 17.4 13.667 17.4H15.5336C16.5603 17.4 17.4003 16.56 17.4003 15.5334V13.6667C17.4003 12.64 16.5603 11.8 15.5336 11.8Z"
          fill="#17494D"
        />
        <path
          d="M4.33343 6.19995H2.46676C1.4401 6.19995 0.600098 5.35995 0.600098 4.33328V2.46662C0.600098 1.43995 1.4401 0.599951 2.46676 0.599951H4.33343C5.3601 0.599951 6.2001 1.43995 6.2001 2.46662V4.33328C6.2001 5.35995 5.3601 6.19995 4.33343 6.19995Z"
          fill="#17494D"
        />
        <path
          d="M11.8003 2.46662V4.33328C11.8003 5.35995 12.6403 6.19995 13.667 6.19995H15.5336C16.5603 6.19995 17.4003 5.35995 17.4003 4.33328V2.46662C17.4003 1.43995 16.5603 0.599951 15.5336 0.599951H13.667C12.6403 0.599951 11.8003 1.43995 11.8003 2.46662Z"
          fill="#17494D"
        />
        <path
          d="M6.2001 13.6667V15.5334C6.2001 16.56 5.3601 17.4 4.33343 17.4H2.46676C1.4401 17.4 0.600098 16.56 0.600098 15.5334V13.6667C0.600098 12.64 1.4401 11.8 2.46676 11.8H4.33343C5.3601 11.8 6.2001 12.64 6.2001 13.6667Z"
          fill="#17494D"
        />
        <path
          d="M4.3335 11.8C5.36016 11.8 6.20016 12.64 6.20016 13.6666C6.20016 12.64 7.04016 11.8 8.06683 11.8C7.04016 11.8 6.20016 10.96 6.20016 9.93329C6.20016 10.96 5.36016 11.8 4.3335 11.8Z"
          fill="#17494D"
        />
        <path
          d="M9.93311 11.8C10.9598 11.8 11.7998 12.64 11.7998 13.6666C11.7998 12.64 12.6398 11.8 13.6664 11.8C12.6398 11.8 11.7998 10.96 11.7998 9.93329C11.7998 10.96 10.9598 11.8 9.93311 11.8Z"
          fill="#17494D"
        />
        <path
          d="M4.3335 6.19998C5.36016 6.19998 6.20016 7.03998 6.20016 8.06665C6.20016 7.03998 7.04016 6.19998 8.06683 6.19998C7.04016 6.19998 6.20016 5.35998 6.20016 4.33331C6.20016 5.35998 5.36016 6.19998 4.3335 6.19998Z"
          fill="#17494D"
        />
        <path
          d="M9.93311 6.19998C10.9598 6.19998 11.7998 7.03998 11.7998 8.06665C11.7998 7.03998 12.6398 6.19998 13.6664 6.19998C12.6398 6.19998 11.7998 5.35998 11.7998 4.33331C11.7998 5.35998 10.9598 6.19998 9.93311 6.19998Z"
          fill="#17494D"
        />
      </svg>
    </Box>
  );
};
